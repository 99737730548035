/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

// Example from: https://react.semantic-ui.com/layouts/homepage

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Input,
  Form,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react'
import Footer from './Footer'
import { v4 as uuidv4 } from 'uuid'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const QuestionnaireContent = ({ mobile }) => {
  const textboxRef = React.useRef(null)
  const bottomRef = React.useRef(null)
  const conversationId = uuidv4()
  const [response, setResponse] = React.useState('')
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [conversation, setConversation] = React.useState([])
  const [state, setState] = React.useState(0)
  const [context, setContext] = React.useState([])
  const [stage, setStage] = React.useState(1)
  const [recommendation, setRecommendation] = React.useState(null)

  // TODO: REMOVE THIS... ITS JUST FOR TESTING
  /*
  React.useEffect(() => {
    setState(2)
    setContext([
      "Major Depression", 
      "Mild", 
      "Major Depression", 
      "Mild", 
      "Age 20 to 40", 
      "Female", 
      "Recurrent Depression", 
      "Full Remission", 
      "No Psychosis", 
      "Not Atypical", 
      "Bupropion", 
      "Bupropion Last", 
      "Bupropion", 
      "Last Remission", 
      "Bupropion Last", 
      "No Psychotic Behavior", 
      "Individual Psychotherapy", 
      "Not Examined for Psychiatric Diagnosis", 
      "Routine Gynecological Examination", 
      "Not Normal Pregnancy", 
      "Anxiety State", 
      "Gynecological Examination", 
      "Not Malaise and Fatigue"
  ])
    setStage(6)
    setConversation([
      {
          "role": "user",
          "content": "yes",
          "stage": 1
      },
      {
          "content": "I'm sorry to hear that you're experiencing this. To better understand your situation, could you please tell me more about the symptoms you've been experiencing? Specifically, have you been experiencing any of the following: \n\n1) persistent low or depressed mood,\n2) decreased interest in pleasurable activities,\n3) feelings of guilt or worthlessness,\n4) lack of energy,\n5) poor concentration,\n6) appetite changes,\n7) psychomotor retardation or agitation,\n8) sleep disturbances,\n9) suicidal thoughts. \n\nPlease note that this information is important for us to provide the best possible support for you.",
          "role": "assistant",
          "stage": 1
      },
      {
          "role": "user",
          "content": "I am experiencing 1, 2, 3, 4, 5, and 6",
          "stage": 1
      },
      {
          "content": "Hello, based on our conversation so far, it appears that you have been diagnosed with Major Depression, and the severity of your depression is considered to be mild. Is this correct? If there's anything you'd like to revise or add, please feel free to share.",
          "role": "assistant",
          "stage": 1
      },
      {
          "role": "user",
          "content": "yeese",
          "stage": 1
      },
      {
          "content": "Great, you've completed 1 out of 9 sections in the medical history intake. Now, could you please tell me your age category and your biological sex at birth? \n\nThe age categories are: \n- 13 to 19\n- 20 to 40\n- 41 to 64\n- 65 to 79\n- 80 plus\n\nAnd the biological sex categories are: \n- Male\n- Female\n\nPlease note, if you are a teenager (13-19), this system is designed for use by a parent or guardian, not by teenagers themselves.",
          "role": "assistant",
          "stage": 2
      },
      {
          "role": "user",
          "content": "I am a 40 year old female",
          "stage": 2
      },
      {
          "content": "Hello, I understand that you are a female between the ages of 20 and 40 and you have been diagnosed with mild major depression. Is this information correct? Please feel free to correct me if I'm wrong.",
          "role": "assistant",
          "stage": 2
      },
      {
          "role": "user",
          "content": "correct",
          "stage": 2
      },
      {
          "content": "Hello, I see that you've completed 2 out of 9 sections in the medical history intake. I have a few more questions to better understand your condition. \n\nFirstly, is this your first episode of depression or have you experienced depression in the past? If you have had previous episodes of depression, in the last 12 months, have there been more than 2 episodes? \n\nAlso, if you've had previous episodes, did you experience full recovery from them? \n\nHave you ever experienced psychosis, which is defined as seeing or hearing things that are not there? Or have you ever experienced delusions, which is defined as paranoia that others are out to get you? \n\nLastly, has a clinician ever told you that you have atypical depression? \n\nYour responses will help us better manage your depression.",
          "role": "assistant",
          "stage": 3
      },
      {
          "role": "user",
          "content": "I experience depression once before at the beginning of the year. I thought I was fully recovered, but I guess not. I don't have psychosis, delusions, paranoia, or atypical depression.",
          "stage": 3
      },
      {
          "content": "Based on our conversation so far, you have been diagnosed with Major Depression, Mild. You are a female between the ages of 20 and 40. You have had recurrent episodes of depression but have fully recovered from them. You have not experienced any psychosis or atypical symptoms during your depressive episodes. \n\nIs there anything you would like to change or add to this description of your depression?",
          "role": "assistant",
          "stage": 3
      },
      {
          "role": "user",
          "content": "that sounds right",
          "stage": 3
      },
      {
          "content": "Hello, I see that you've made good progress with your medical history intake. You've completed 3 out of 9 sections so far. \n\nI understand that you've had recurrent episodes of depression. During these episodes, did you take any antidepressants to manage your symptoms? If so, could you please tell me which one(s)? Some common antidepressants include amitriptyline, bupropion, citalopram, desvenlafaxine, doxepin, duloxetine, escitalopram, fluoxetine, mirtazapine, nortriptyline, paroxetine, ropinirole, sertraline, trazodone, venlafaxine, or perhaps another unspecified antidepressant or combination of antidepressants.",
          "role": "assistant",
          "stage": 4
      },
      {
          "role": "user",
          "content": "I took bupropion",
          "stage": 4
      },
      {
          "content": "Thank you for sharing that information. Now, I'd like to know how you responded to bupropion. In medical terms, a response to an antidepressant is defined as a reduction of 50% of symptoms after taking the medication for 100 days. Would you say that you experienced this level of improvement with bupropion?",
          "role": "assistant",
          "stage": 4
      },
      {
          "role": "user",
          "content": "Yes it helped",
          "stage": 4
      },
      {
          "content": "Hello, I see from our records that you have been taking Bupropion for your depression. Can you please confirm if you have taken any other antidepressants apart from Bupropion? Also, was Bupropion the last antidepressant you took? And did you experience remission with the use of Bupropion?",
          "role": "assistant",
          "stage": 4
      },
      {
          "role": "user",
          "content": "That was the only antidepressant I took.  I experienced a full remission with it.",
          "stage": 4
      },
      {
          "content": "Hello, I hope you're doing well. I'd like to inform you that we've completed 4 out of 9 sections in the medical history intake. Now, I have a few more questions for you. \n\nIn the past year, have you participated in psychotherapy for more than 4 sessions? If yes, was it family or individual therapy? \n\nAlso, have you ever experienced any psychotic behavior?",
          "role": "assistant",
          "stage": 5
      },
      {
          "role": "user",
          "content": "Yes individual psychotherapy.  I did not experience psychotic behavior",
          "stage": 5
      },
      {
          "content": "Hello, based on our conversation so far, it appears that you have participated in individual psychotherapy and have not experienced any psychotic behavior. Is this information correct?",
          "role": "assistant",
          "stage": 5
      },
  ])
  }, [])
  */

  React.useEffect(() => {
    // scroll to bottom every time messages change
    bottomRef?.current?.scrollIntoView({behavior: 'smooth'});
    // textboxRef?.current?.scrollIntoView();
  }, [conversation]);

  const chat = async (event) => {
    event.preventDefault()

    setIsLoading(true)
    const userMessage = {role: 'user', content: response, stage: stage}
    const currentConversation = [...conversation, userMessage]
    const existingState = state
    const existingContext = context
    const existingStage = stage
    const existingRecommendation = recommendation

    const request = new Request('./chat', {
      method: "POST",
      body: JSON.stringify({
        currentConversation,
        conversationId,
        existingState,
        existingContext,
        existingStage,
        existingRecommendation,
      }),
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
    });

    try {
      const response = await fetch(request)
      if (response.status === 200) {
        const postResponse = await response.json()

        currentConversation.push(postResponse?.next_message)
        setState(postResponse?.existing_state)
        setContext(postResponse?.existing_context)
        setStage(postResponse?.existing_stage)
        setResponse('')
        setConversation(currentConversation)
        setIsLoading(false)
        setRecommendation(postResponse?.recommendation)
      } else {
        throw new Error();
      }
    } catch (error) {
      setError("Something went wrong! Try again after some time.")
      setIsLoading(false)
    }
  }

  const displayConversation = () => {
    const firstPrompt = [
      (<p style={{ color: '#2185d0' }}>
          <strong>Chatbot:</strong> I am a mental health chatbot that recommends antidepressants. Before we get started, can I ask if you have you experienced major depression.
        </p>)
    ]
    const dynamicConversation = conversation.map(item => {
      if (item.role === 'assistant') {
        return (<p style={{ color: '#2185d0' }}>
          <strong>Chatbot:</strong> {item.content}
        </p>)
      } else {
        return (<p>
          <strong>You:</strong> {item.content}
        </p>)
      }

    })
    return  [...firstPrompt, ...dynamicConversation]
  }

  return (
    <Container text>
      <Header
        as='h2'
        content='Antidepressant Selector'
        inverted
        style={{
          marginBottom: 0,
          marginTop: mobile ? '1.5em' : '3em',
        }}
      />
      { error && (
        <Message negative onDismiss={() => setError(null)}>
        <Message.Header>{error}</Message.Header>
      </Message>
      )}
      <div style={{ margin: 20, border: '1px solid white', textAlign: 'left', padding: 10, maxHeight: '65vh', overflow: 'scroll' }}>
        {displayConversation()}
        <div ref={bottomRef} />
      </div>
      <div ref={textboxRef} style={{ display: 'flex', margin: 20 }}>
        <Form onSubmit={chat} style={{ width: '100%' }}>
          <Input action={{ icon: 'arrow alternate circle right outline' }} placeholder='Response...' size='small' style={{ flex: 1, width: '100%', paddingRight: 38 }} value={response}
            onChange={(_e, { value }) => setResponse(value)} />
        </Form>
      </div>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
    </Container>
  )
}

QuestionnaireContent.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = {}

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Media greaterThan='mobile'>
        <Segment
          inverted
          textAlign='center'
          style={{ minHeight: 700, padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size='large'
          >
            <Container>
              <Menu.Item as='a' href='/'>
                Home
              </Menu.Item>
              <Menu.Item as='a' href='/disclaimer'>Disclaimer</Menu.Item>
              <Menu.Item as='a' href='/about'>About</Menu.Item>
            </Container>
          </Menu>
          <QuestionnaireContent />
        </Segment>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as='a' active href='/'>
              Home
            </Menu.Item>
            <Menu.Item as='a' href='/disclaimer'>Disclaimer</Menu.Item>
            <Menu.Item as='a' href='/about'>About</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 70, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right'>

                  </Menu.Item>
                </Menu>
              </Container>
              <QuestionnaireContent mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const Questionnaire = () => (
  <ResponsiveContainer>
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Footer />
    </Segment>
  </ResponsiveContainer>
)

export default Questionnaire
