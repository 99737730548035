import React from 'react'
import {
  Container,
  Grid,
  Header,
} from 'semantic-ui-react'

const Footer = () => (
  <Container>
    <Grid divided inverted stackable>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <p>
            Experimental system of George Mason University licensed to Rapid Improvement
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
)

export default Footer
