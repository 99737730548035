import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import Footer from './Footer'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

class DesktopContainer extends Component {
  state = {}

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Media greaterThan='mobile'>
        <Segment
          inverted
          textAlign='center'
          style={{ minHeight: 80, padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size='large'
          >
            <Container>
              <Menu.Item as='a' href='/'>
                Home
              </Menu.Item>
              <Menu.Item as='a' active href='/disclaimer'>Disclaimer</Menu.Item>
              <Menu.Item as='a' href='/about'>About</Menu.Item>
            </Container>
          </Menu>
        </Segment>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as='a' active href='/'>
              Home
            </Menu.Item>
            <Menu.Item as='a' href='/disclaimer'>Disclaimer</Menu.Item>
            <Menu.Item as='a' href='/about'>About</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 70, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right'>

                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const Disclaimer = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Medical Disclaimer
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              This website does not contain medical advice. The contents of this website, such as text, graphics, images and other material are intended for informational and educational purposes only and not for the purpose of rendering medical advice. The contents of this website are not intended to substitute for professional medical advice, diagnosis or treatment. Although we take efforts to keep the site updated, we cannot guarantee that the information on our website reflects the most up-to-date research.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              Please consult your physician for personalized medical advice. Always seek the advice of a physician or other qualified healthcare provider with any questions regarding a medical condition. Never disregard or delay seeking professional medical advice or treatment because of something you have read on this site.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              Before taking any antidepressants or changing your current antidepressant, consult a physician for a thorough evaluation. Sudden changes in antidepressants can make the situation a lot worse. A qualified physician should make a decision based on each person's medical history and current prescriptions. The site advice does not include all of the information important for selection of antidepressants and should not be used as a substitute for professional medical advice. The prescribing physician should be consulted concerning any questions that you have. The site provides a feature for you to share the information with your clinician.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              In a medical emergency call 911 immediately. This site does not recommend or endorse any specific medication, physician, product, procedure, opinion or any other information provided on its website. Reliance on any information provided by the site, university employees, others represented on the website, is solely at your own risk.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Footer />
    </Segment>
  </ResponsiveContainer>
)

export default Disclaimer
