/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

// Example from: https://react.semantic-ui.com/layouts/homepage

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const HomepageContent = ({ mobile }) => {
  const navigate = useNavigate()

  return (
  <Container text>
    <Header
      as='h1'
      content='Antidepressant Selector'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='George Mason University'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '2em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1em',
        marginBottom: mobile ? '0.5em' : '1em',
      }}
    />
    <Button primary size='huge' onClick={() => navigate('/questionnaire')}>
      Get Started
      <Icon name='right arrow' />
    </Button>
  </Container>
  )
}

HomepageContent.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = {}

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Media greaterThan='mobile'>
        <Segment
          inverted
          textAlign='center'
          style={{ minHeight: 700, padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size='large'
          >
            <Container>
              <Menu.Item as='a' active href='/'>
                Home
              </Menu.Item>
              <Menu.Item as='a' href='/disclaimer'>Disclaimer</Menu.Item>
              <Menu.Item as='a' href='/about'>About</Menu.Item>
            </Container>
          </Menu>
          <HomepageContent />
        </Segment>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as='a' active href='/'>
              Home
            </Menu.Item>
            <Menu.Item as='a' href='/disclaimer'>Disclaimer</Menu.Item>
            <Menu.Item as='a' href='/about'>About</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 70, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right'>

                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageContent mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const Home = () => (
  <ResponsiveContainer>
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Footer />
    </Segment>
  </ResponsiveContainer>
)

export default Home
